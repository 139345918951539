@import 'styles/variables';
@import 'styles/extensions';


.mobile-hidden {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.main {
  overflow: hidden;

  h2 {
    @extend %header-level-2;
  }

  &__contact-us-form {
    @extend %wrapper;
    display: flex;
    justify-content: space-between;
    max-width: 1140px;

    @media (max-width: 1024px) {
      flex-direction: column;
    }

    @media (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
    }
  }

  &__contact-us-form-title-col {
    flex-basis: 30%;
    max-width: 345px;

    @media (max-width: 1024px) {
      max-width: 100%;
      margin-bottom: 50px;
    }

    @media (min-width: 2560px) {
      flex-basis: calc(30% * #{$zoom});
      max-width: calc(345px * #{$zoom});
    }
  }

  &__form-wrapper {
    flex-basis: 65%;
    max-width: 685px;
    margin-bottom: 50px;

    @media (min-width: 2560px) {
      flex-basis: calc(65% * #{$zoom});
      max-width: calc(685px * #{$zoom});
    }

    .form-box {      
      &__row {
        display: flex;
        justify-content: space-between;

        &_without-flex {
          display: block;
        }

        @media (max-width: 475px) {
          display: block;
        }
      }

      &__col {
        flex-basis: 49%;
      }  
      
      &__description {
        font-size: 14px;

        a {
          font-weight: 800;
          text-decoration: underline;
          -webkit-text-decoration-color: $gold;
          text-decoration-color: $gold;
          background: linear-gradient(0deg, #EAB938, #EAB938);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        
        @media (min-width: 2560px) {
          font-size: calc(14px * #{$zoom});
          line-height: calc(18px * #{$zoom});
        }
      }
    }

    .form-input {
      &__text,
      &__textarea {
        &::-webkit-input-placeholder {
          color: $black;
          font-weight: 800;
          font-family: $basic-font;
          text-transform: none;
        }
    
        &::-moz-placeholder {
          color: $black;
          font-weight: 800;
          font-family: $basic-font;
          text-transform: none;
        }
    
        &:-moz-placeholder {
          color: $black;
          font-weight: 800;
          font-family: $basic-font;
          text-transform: none;
        }
    
        &:-ms-input-placeholder {
          color: $black;
          font-weight: 800;
          font-family: $basic-font;
          text-transform: none;
        }
      }

      &__textarea {
        min-height: 80px;

        @media (max-width: 475px) {
          min-height: 120px;
          margin-bottom: 30px;
        }
      }

      &__checkbox {
        @media (min-width: 767px) {
          max-width: 70%;
        }
      }

      &__checkbox-label {
        color: $black;

        &::before {
          color: $white;
          background-color: $white;
          border-color: $black;
        }

        &:hover, &:focus {
          &::before {
            color: $white;
            background-color: $black;
            border-color: $black;
          }
        }
      }
    }
  }

  &__contact-us-form-title {
    font-weight: 800;
    font-size: 60px;
    font-family: $secondary-font;
    line-height: 64px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (max-width: 475px) {
      font-size: 40px;
      line-height: 48px;
    }
  }

  &__form-title {
    margin-bottom: 10px;
    font-weight: 800;
    font-size: 32px;
    font-family: $secondary-font;
    line-height: 36px;
    text-transform: uppercase;

    @media (max-width: 475px) {
      font-size: 24px;
      line-height: 32px;
    }

    @media (min-width: 2560px) {
      font-size: calc(32px * #{$zoom});
      line-height: calc(36px * #{$zoom});
    }
  }

  &__form-description {
    margin-bottom: 50px;
    
    @media (min-width: 2560px) {
      font-size: calc(16px * #{$zoom});
      line-height: calc(20px * #{$zoom});
    }
  }

  &__form-button {
    @extend %button-colored;
    width: 100%;
    max-width: 376px;
    height: 48px;
    margin-left: 0;
    line-height: 48px;

    @media (min-width: 2560px) {
      max-width: calc(376px * #{$zoom});
      height: calc(48px * #{$zoom});
      font-size: calc(18px * #{$zoom});    
      line-height: calc(48px * #{$zoom});   
    }
  }

  &__success {
    margin-bottom: 300px;

    @media (min-width: 2560px) {
      margin-bottom: calc(300px * #{$zoom});
    }

    @media (max-width: 1024px) {
      margin-top: 100px;
      margin-bottom: 150px;
    }

    @media (max-width: 475px) {
      text-align: center;
    }
  } 
}

.hero-main {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  min-height: 700px;

  @media screen and (max-width: 820px) {
    justify-content: center;
    background-image: none;
  }

  @media screen and (max-width: 475px) {
    height: 100vh;
  }

  &__video{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100vw;
    min-width: 100%;
    height: 100vh;
    min-height: 100%;
    object-fit: cover;
    padding: 0;
  }

  &__title {
    position: relative;
    z-index: 2;
    max-width: 628px;
    margin-left: 144px;
    color: $white;

    @media screen and (min-width: 2560px) {
      max-width: calc(628px * #{$zoom});
      margin-left: calc(144px * #{$zoom});
    }

    @media screen and (max-width: 820px) {
      margin-left: 0;
      padding: 0 20px;
    }

    h1 {
      font-weight: 900;
      font-size: 80px;
      font-family: $secondary-font;
      font-style: normal;
      line-height: 80%;
      text-transform: uppercase;

      @media screen and (min-width: 2560px) {
        font-size: calc(80px * #{$zoom});
      }

      @media screen and (max-width: 820px) {
        font-size: 7vw;
        text-align: center;
      }

      @media screen and (max-width: 475px) {
        font-size: 10vw;
        text-align: left;
      }
    }

    p {
      max-width: 467px;
      margin-top: 55px;
      font-size: 28px;

      @media screen and (min-width: 2560px) {
        max-width: calc(467px * #{$zoom});
        font-size: calc(28px * #{$zoom});
      }

      @media screen and (max-width: 820px) {
        max-width: 100%;
        font-size: 20px;
        text-align: center;
      }

      @media screen and (max-width: 475px) {
        text-align: left;
      }
    }
  }

  &__more {
    position: absolute;
    right: 50%;
    bottom: 0;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding-bottom: 24px;
    color: $white;
    transform: translate(-50%, -50%);

    @media screen and (min-width: 2560px) {
      padding-bottom: calc(24px * #{$zoom});
      font-size: calc(16px * #{$zoom});
    }
    
    @media screen and (max-width: 475px) {
      padding-bottom: 40px;
    }
    
    &-icon {
      display: block;
      padding-top: 8px;
      -webkit-filter: invert(1);
      filter: invert(1);
    }
  }
}

.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 90px 40px 0;

  @media screen and (max-width: 768px) {
    min-height: fit-content;
    padding: 0 20px;
  }

  @media screen and (max-width: 475px) {
    justify-content: flex-start;
    min-height: fit-content;
  }

  &__text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 1096px;

    @media screen and (min-width: 2560px) {
      max-width: calc(1096px * #{$zoom});
    }

    @media screen and (max-width:1024px) {
      flex-direction: column-reverse;
    }

    p {
      &:first-of-type {
        max-width: 300px;
        font-weight: 400;
        font-size: 16px;
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        line-height: 22px;

        @media screen and (min-width: 2560px) {
          max-width: calc(300px * #{$zoom});
          font-size: calc(16px * #{$zoom});
          line-height: calc(22px * #{$zoom});
        }

        @media screen and (max-width:1024px) {
          max-width: 100%;
          padding-top: 40px;
          text-align: center;
        }
      }

      &:nth-of-type(2) {
        max-width: 538px;
        font-weight: 400;
        font-size: 28px;
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        line-height: 38px;

        @media screen and (min-width: 2560px) {
          max-width: calc(538px * #{$zoom});
          font-size: calc(28px * #{$zoom});
          line-height: calc(38px * #{$zoom});
        }

        @media screen and (max-width: 1024px) {
          max-width: 100%;
          padding-top: 40px;
          text-align: center;
        }

        @media screen and (max-width: 475px) {
          font-size: 20px;
          line-height: 30px;
        }
      }

    }
  }

  &__lego {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1096px;

    @media screen and (min-width: 2560px) {
      max-width: calc(1096px * #{$zoom});
    }

    p {
      font-weight: 400;
      font-size: 24px;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      line-height: 33px;
      text-align: center;

      @media screen and (min-width: 2560px) {
        font-size: calc(24px * #{$zoom});
        line-height: calc(33px * #{$zoom});
      }

      @media screen and (max-width: 475px) {
        font-size: 20px;
      }

      &:first-of-type {
        padding-top: 160px;

        @media screen and (min-width: 2560px) {
          padding-top: calc(160px * #{$zoom});
        }

        @media screen and (max-width: 475px) {
          padding-top: 40px;
          line-height: 30px;
        }
      }

      &:last-of-type {
        padding-top: 40px;
        font-weight: 700;

        @media screen and (min-width: 2560px) {
          padding-top: calc(40px * #{$zoom});
        }

        @media screen and (max-width: 475px) {
          padding-top: 20px;
        }
      }
    }
  }

  &__image-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
    max-width: 1096px;
    padding-top: 60px;

    @media screen and (min-width: 2560px) {
      max-width: calc(1096px * #{$zoom});
      padding-top: calc(60px * #{$zoom});
    }

    img {
      position: relative;
      max-width: 50%;

      &:first-of-type {
        left: 3%;
        clip-path: polygon(0% 0%, 100% 0%, 80% 100%, 0% 100%);
      }

      &:nth-of-type(2) {
        left: -3%;
        clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
      }
    }
  }

  a {
    @extend %anchor-btn;
    margin-top: 40px;

    @media screen and (min-width: 2560px) {
      margin-top: calc(40px * #{$zoom});
    }

    @media screen and (max-width: 475px) {
      margin-top: 20px;
    }
  }
}


.how-it-works {
  padding: 200px 40px 0;
  background-color: $white;

  @media screen and (min-width: 2560px) {
    padding: calc(150px * #{$zoom}) 40px 0;
  }

  @media screen and (max-width: 768px) {
    padding: 110px 20px 0;
  }

  &__title, &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__title {
    padding-bottom: 90px;
    color: $black;

    @media screen and (max-width: 768px) {
      padding-bottom: 40px;
    }

    h2 {
      max-width: 710px;

      @media screen and (min-width: 2560px) {
        max-width: calc(710px * #{$zoom});
      }
    }

    p {
      @extend %pre-heading;
    }
  }

  &__content {
    row-gap: 150px;

    @media screen and (max-width: 768px) {
      row-gap: 75px;
    }
  }
}

.how-to-use, .try-it-out, .demo-request, .use-cases {
  /*min-height: 100vh;*/

  @media screen and (max-width: 475px) {
    min-height: fit-content;
  }
}

.how-to-use {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 110px 40px 0px;
  background-color: $white;

  @media screen and (min-width: 2560px) {
    padding: calc(150px * #{$zoom}) 40px;
  }

  @media screen and (max-width: 475px) {
    padding: 100px 20px 0;    
  }

  &__title {

    h2 {
      max-width: 710px;
      
      @media screen and (min-width: 2560px) {
        max-width: calc(710px * #{$zoom});
      }
    }

    p {
      @extend %pre-heading;
      color: #B6BFCD;
    }
  }

  &__cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 1096px;
    padding-top: 60px;

    @media screen and (min-width: 2560px) {
      max-width: calc(1096px * #{$zoom});
      padding-top: calc(60px * #{$zoom});
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    @media screen and  (max-width: 475px) {
      padding-top: 40px;
    }
  }

}

.use-cases {
  margin-top: 85px;
  padding-top: 40px;

  @media screen and (max-width: 768px) {
    min-height: fit-content;
  }

  @media screen and (max-width: 475px) {
    margin-top: 0;
    padding-top: 50px;
  }
}

.try-it-out {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 110px 40px 40px;
  background-color: $white;

  @media screen and (min-width: 2560px) {
    padding: calc(80px * #{$zoom}) 40px 0;
  }

  @media screen and (max-width: 475px) {
    padding: 100px 20px 0;
  }

  &__title {
    width: 100%;
    max-width: 1096px;
    text-align: start;

    @media screen and (min-width: 2560px) {
      max-width: calc(1096px * #{$zoom});
    }
    
    h2 {
      max-width: 710px;
      text-align: left;

      @media screen and (min-width: 2560px) {
        max-width: unset;
      }
    }
    
    p {
      @extend %pre-heading;
      text-align: left;
    }
  }
  
  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1096px;
    padding-top: 40px;

    @media screen and (min-width: 2560px) {
      max-width: calc(1096px * #{$zoom});
      padding-top: calc(40px * #{$zoom});
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    img {
      width: 50%;
      height: 100%;
    }

    &-image-wrapper {
      img {
        width: 50%;
        height: 100%;
      }
    }

    &-text {
      padding-left: 20px;
      font-size: 18px;

      @media screen and (min-width: 2560px) {
        font-size: calc(24px * #{$zoom});
      }

      @media screen and (max-width: 475px) {
        padding-left: 0;
      }
  
      p {
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;

        @media screen and (min-width: 2560px) {
          font-size: calc(24px * #{$zoom});
          line-height: calc(33px * #{$zoom});
        }

        @media screen and (max-width: 1024px) {
          margin-top: 40px;
        }

        @media screen and (max-width: 475px) {
          font-size: 20px;
          line-height: 26px;
        }
      }

      .list-title {
        margin-top: 24px;
        margin-left: 60px;
        font-size: 16px;

        @media screen and (min-width: 2560px) {
          margin-top: calc(24px * #{$zoom});
          margin-left: calc(60px * #{$zoom});
          font-size: calc(16px * #{$zoom});
        }
      }

      ol {
        margin-left: 40px;
        padding-top: 10px;
        font-weight: 400;
        list-style-position: outside;
        list-style-type: decimal-leading-zero;
        counter-reset: custom-counter;

        @media screen and (min-width: 2560px) {
          margin-left: calc(40px * #{$zoom});
          padding-top: calc(10px * #{$zoom});
        }

        @media screen and (max-width: 475px) {
          padding-top: 10px;
        }
      }

      li {
        position: relative;
        padding-left: 20px;
        font-size: 16px;
        counter-increment: custom-counter;

        @media screen and (min-width: 2560px) {
          padding-left: calc(16px * #{$zoom});
          font-size: calc(16px * #{$zoom});
        }

        @media screen and (max-width: 475px) {
          line-height: 20px;
        }

        &:not(:first-of-type) {
          margin-top: 16px;

          @media screen and (max-width: 768px) {
            margin-top: 20px;
          }

          @media screen and (max-width: 475px) {
            margin-top: 16px;
          }
        }

        &::marker {
          padding-right: 40px;
          color:#2B5FC3;
          font-weight: 400;
          font-size: 16px;
          content: "0" counter(custom-counter) ". ";

          @media screen and (min-width: 2560px) {
            font-size: calc(16px * #{$zoom});
          }
        }
      }
    }
  }

  .assistance-wrapper {
    width: 100%;
    max-width: 1096px;
    padding-top: 50px;

    @media screen and (min-width: 2560px) {
      max-width: calc(1096px * #{$zoom});
      padding-top: calc(50px * #{$zoom});
    }
  }

  &__assistance {
    display: flex;
    flex-direction: row;
    width: 100%;
    column-gap: 60px;
    border-top: 2px solid #EEF1F6;
    @media screen and (min-width: 2560px) {
      max-width: calc(1096px * #{$zoom});
      margin-top: calc(65px * #{$zoom});
    }

    @media screen and (max-width: 768px) {
      justify-content: space-between;
      column-gap: 0;
    }

    img {
      position: relative;
      top: -8.5px;
      max-width: 82px;
      height: 137px;

      @media screen and (min-width: 2560px) {
        max-width: calc(82px * #{$zoom});
        height: calc(137px * #{$zoom});
      }

      @media screen and (max-width: 768px) {
        margin-right: 40px;
      }
    }

    &-text, &-text-highlight {
      margin-top: 25px;
    }

    &-text {
      max-width: 580px;

      @media screen and (min-width: 2560px) {
        max-width: calc(580px * #{$zoom});
      }

      p {
        @media screen and (min-width: 2560px) {
          font-size: calc(16px * #{$zoom});
        }

        &:first-of-type {
          padding-bottom: 15px;
          font-weight: 900;
        }
      }
    }

    &-text-highlight, &-text-highlight_mobile {
      max-width: 350px;
      padding: 17px 25px;
      background-color: #EEF1F6;
      border-radius: 5px;
    }

    &-text-highlight {
      @media screen and (min-width: 2560px) {
        max-width: calc(350px * #{$zoom});
        font-size: calc(16px * #{$zoom});
      }

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    &-text-highlight_mobile {
      display: none;

      @media screen and (max-width: 768px) {
        display: block;
        max-width: 100%;
        margin-top: 40px;
      }
    }
  }
}

.lets-talk {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 40px;

  @media screen and (max-width: 475px) {
    padding: 0 20px;
  }

  &__info-wrapper {
    width: 100%;
    max-width: 1096px;
    margin-top: 150px;

    @media screen and (min-width: 2560px) {
      max-width: calc(1096px * #{$zoom});
    }

    @media screen and (max-width: 475px) {
      margin-top: 80px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 90px;
    background-color: #EEF1F6;
    border-radius: 5px;
    
    @media screen and (min-width: 2560px) {
      gap: calc(40px * #{$zoom});
    }

    @media screen and (max-width: 768px) {
      padding: 60px 40px;
    }

    p {
      font-weight: 700;
      font-size: 24px;
      font-style: normal;
      line-height: 33px;
      text-align: center;

      @media screen and (min-width: 2560px) {
        font-size: calc(24px * #{$zoom});
        line-height: calc(33px * #{$zoom});
      }

      @media screen and (max-width: 475px) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    a {
      @extend %anchor-btn;
    }
  }
}

.demo-request {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh!important;
  padding: 110px 40px 0;

  @media screen and (max-width: 475px) {
    padding: 100px 20px 0;
  }

  &__content {
    display: flex;
    flex-direction: row;
    gap: 20px;
    max-width: 1096px;

    @media screen and (min-width: 2560px) {
      max-width: calc(1096px * #{$zoom});
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 40px;
    }

    > div {
      max-width: calc(50% - 20px);

      @media screen and (max-width: 768px) {
        max-width: 100%;
      }
    }
  }

  &__text {
    h2 {
      text-align: left;
    }

     p {
      padding-top: 40px;
      font-weight: 700;
      font-size: 24px;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      line-height: 33px;

      @media screen and (min-width: 2560px) {
        font-size: calc(24px * #{$zoom});
        line-height: calc(33px * #{$zoom});
      }

      @media screen and (max-width: 475px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}


.prize {
  padding: 35px 0;
  font-size: 34px!important;
  line-height: 46px!important;
}