// @font-face {
//   font-weight: 400;
//   font-family: 'ProximaNova';
//   src: url('https://info.softserveinc.com/hubfs/fonts/ProximaNovaBlack.otf');
// }

// @font-face {
//   font-weight: 900;
//   font-family: 'ProximaNova';
//   src: url('https://info.softserveinc.com/hubfs/ibm-think/assets/fonts/ProximaNova/Regular/ProximaNova-Bold.ttf');
// }

// @font-face{
//   font-weight: normal;
//   font-family: 'OpenSans';
//   src: url('https://info.softserveinc.com/hubfs/fonts/OpenSans-Regular.ttf');
// }


@font-face {
  font-weight: 900;
  font-family: 'ProximaNova';
  font-style: normal;
  src: url('#{$cdnURL}/fonts/ProximaNova/ProximaNova-Black.eot');
  src: url('#{$cdnURL}/fonts/ProximaNova/ProximaNova-Black.eot?#iefix') format('embedded-opentype'),
    url('#{$cdnURL}/fonts/ProximaNova/ProximaNova-Black.woff2') format('woff2'),
    url('#{$cdnURL}/fonts/ProximaNova/ProximaNova-Black.woff') format('woff'),
    url('#{$cdnURL}/fonts/ProximaNova/ProximaNova-Black.ttf') format('truetype'),
    url('#{$cdnURL}/fonts/ProximaNova/ProximaNova-Black.svg#ProximaNova-Black') format('svg');
}
@font-face {
  font-weight: 400;
  font-family: 'OpenSans';
  font-style: normal;
  src: url('#{$cdnURL}/fonts/OpenSans/OpenSans.eot');
  src: url('#{$cdnURL}/fonts/OpenSans/OpenSans.eot?#iefix') format('embedded-opentype'),
    url('#{$cdnURL}/fonts/OpenSans/OpenSans.woff2') format('woff2'),
    url('#{$cdnURL}/fonts/OpenSans/OpenSans.woff') format('woff'),
    url('#{$cdnURL}/fonts/OpenSans/OpenSans.ttf') format('truetype'),
    url('#{$cdnURL}/fonts/OpenSans/OpenSans.svg#OpenSans') format('svg');
}