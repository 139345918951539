@import 'styles/variables';
@import 'styles/extensions';

$column-gap: 30px;
$item-width: calc(50% - $column-gap);


.iwd-content {
    display: flex;
    gap: 60px;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1096px;
    color: $black;

    @media screen and (min-width: 2560px) {
        max-width: calc(1096px * #{$zoom});
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }

    &:nth-of-type(2n) {
        flex-direction: row-reverse;

        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        gap: $column-gap;
        max-width: 525px;

        @media screen and (min-width: 2560px) {
            max-width: calc(525px * #{$zoom});
        }

        @media screen and (max-width: 768px) {
            max-width: 100%;
        }

        h3 {
            font-weight: 900;
            font-size: 32px;
            font-family: $secondary-font;
            font-style: normal;
            line-height: 80%;
            text-transform: uppercase;

            @media screen and (min-width: 2560px) {
                font-size: calc(32px * #{$zoom});
            }

            @media screen and (max-width: 475px) {
                font-size: 24px;
            }
        }

        img {
            width: 52px;

            @media screen and (min-width: 2560px) {
                width: calc(52px * #{$zoom});
            }
        }

        p {
            font-size: 16px;
            @media screen and (min-width: 2560px) {
                font-size: calc(16px * #{$zoom});
            }
        }

        ul {
            margin-top: 10px;
            padding-left: 20px;
        }
    }

    &__image-wrapper {
        min-width: $item-width;
        max-width: $item-width;

        @media screen and (max-width: 768px) {
            min-width: 100%;
            max-width: 100%;
        }

        img {
            width: 100%;
            max-height: 265px;
            object-fit: contain;

            @media screen and (min-width: 2560px) {
                max-height: calc(265px * #{$zoom});
            }
        }
    }
}