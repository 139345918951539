@import 'styles/variables';
@import 'styles/fonts';
@import-normalize;

@supports (padding: max(0px)) {
  body,
  header,
  footer {
    padding-top: env(safe-area-inset-top);
    padding-right: env(safe-area-inset-right);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
  }
}

html {
  box-sizing: border-box;
}

body {
  color: $black;
  font-weight: normal;
  font-size: 16px;
  font-family: $basic-font;  
  font-style: normal;
  letter-spacing: 0em;
  // background: linear-gradient(107.26deg, #0D0D0D 10.43%, #060724 36.29%, #0C0F35 52.45%, #010944 106.97%, rgba(5, 5, 5, 0) 165.6%);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body,
ul,
ol,
figure,
p {
  margin:  0;
  padding: 0;
}

h1, h2, h3, h4, h5 {
  margin: 0;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0em;
}

h1 {
  font-weight: 900;
}
