@import 'styles/variables';
@import 'styles/extensions';

$image-width: 135px;
$image-height:  158px;

.use-card {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: flex-start;
    width: calc(50% - 10px);
    min-height: 280px;
    padding: 40px;
    overflow: hidden;
    color: $black;
    background-image: url('../../../asset/resource/use-background-1.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 10px;

    filter: drop-shadow(5px 12px 15px rgba(0, 0, 0, 0.35));

    &:nth-of-type(2n) {
        background-image: url('../../../asset/resource/use-background-2.png');
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column-reverse;
        justify-content: start;
        height: fit-content;
        min-height: 380px;
        background-size: cover;
    }

    @media screen and (min-width: 2560px) {
        min-height: calc(350px);
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        min-height: fit-content;
    }

    &__text {

        @media screen and (max-width: 1024px) {
            width: 100%;
        }

        h3 {
            width: 75%;
            font-weight: 900;
            font-size: 32px;
            font-family: $secondary-font;
            font-style: normal;
            line-height: 80%;
            text-transform: uppercase;

            @media screen and (min-width: 2560px) {
                font-size: calc(32px * #{$zoom});
            }

            @media screen and (max-width: 1024px) {
                width: 100%;
            }

            @media screen and (max-width: 475px) {
                font-size: 24px;
            }
        }

        p {
            padding-top: 30px;
            font-weight: 400;
            font-size: 16px;
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            line-height: 22px;

            @media screen and (min-width: 2560px) {
                font-size: calc(16px * #{$zoom});
                line-height: calc(22px * #{$zoom});
            }
        }
    }

    &__image-wrapper {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 100%;

        // img {
        //     max-width: $image-width;
        //     max-height: $image-height;

        //     @media screen and (max-width: 1024px) {
        //         max-width: calc($image-width / 2);
        //         max-height: calc($image-height / 2);
        //     }
        // }
    }
}